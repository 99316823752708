import { useStyletron } from "baseui";
import type { ItemsT, MenuOverrides } from "baseui/menu";
import { StatefulMenu, StyledListItem } from "baseui/menu";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import type { ReactNode } from "react";
import React, { forwardRef, useState } from "react";
import type { StyleObject } from "styletron-standard";
import { AccordionExpandIcon } from "../icons/AccordionExpandIcon";
import { expandBorderRadii, expandBorderTop, marginHorizontal, marginVertical, paddingHorizontal, paddingVertical } from "../utils";
import { Row } from "./Row";

export type DropdownMenuProps = {
  items: ItemsT;
  fullWidth?: boolean;
  overrides?: {
    menu?: MenuOverrides;
    triggerButton?: StyleObject;
  };
  placement?: keyof PLACEMENT;
  children: ReactNode;
  overrideArrowDirection?: "up" | "down";
};

const ContextMenuListItem = forwardRef<HTMLLIElement, React.ComponentProps<typeof StyledListItem>>((props, ref) => {
  const [_css, $theme] = useStyletron();

  return (
    <StyledListItem
      ref={ref}
      {...props}
      $style={{
        ...(props.item.divider
          ? {
              ...expandBorderTop({
                borderColor: $theme.colors.borderOpaque,
                borderStyle: "solid",
                borderWidth: "1px",
              }),
              transform: `translateY(${$theme.sizing.scale200})`,
              ...paddingVertical($theme.sizing.scale200),
              height: 0,
              pointerEvents: "none",
            }
          : {
              ...paddingHorizontal($theme.sizing.scale300),
              ...paddingVertical($theme.sizing.scale200),
              ...marginHorizontal($theme.sizing.scale200),
              borderRadius: $theme.sizing.scale200,
            }),
      }}
      aria-label={props.item.ariaLabel ?? props["aria-label"]}
    />
  );
});

export const DropdownMenu = (props: DropdownMenuProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [css, $theme] = useStyletron();

  return (
    <StatefulPopover
      onOpen={() => setMenuOpen(true)}
      onClose={() => setMenuOpen(false)}
      placement={props.placement ?? PLACEMENT.bottomRight}
      popoverMargin={0}
      content={({ close }) => (
        <StatefulMenu
          overrides={{
            List: {
              style: {
                width: "140px",
                overflow: "auto",
                boxShadow: "none",
                boxSizing: "content-box",
                paddingTop: $theme.sizing.scale200,
                paddingBottom: $theme.sizing.scale200,
              },
            },
            Option: {
              props: {
                overrides: {
                  ListItem: {
                    component: ContextMenuListItem,
                  },
                },
              },
            },
            ...props.overrides?.menu,
          }}
          items={props.items}
          onItemSelect={({ item }) => {
            item.onSelect();
          }}
        />
      )}
      overrides={{
        Body: {
          style: {
            ...marginVertical($theme.sizing.scale200),
          },
        },
      }}
    >
      <Row
        $gap={$theme.sizing.scale500}
        className={css({
          paddingTop: $theme.sizing.scale200,
          paddingBottom: $theme.sizing.scale200,
          paddingLeft: $theme.sizing.scale300,
          paddingRight: $theme.sizing.scale300,
          ...expandBorderRadii($theme.borders.radius200),
          backgroundColor: menuOpen ? $theme.colors.alpha50 : undefined,
          boxShadow: menuOpen ? $theme.lighting.shadowButtonInset : undefined,
          cursor: "pointer",
          userSelect: "none",
          overflow: "hidden",
          ":hover": {
            backgroundColor: $theme.colors.alpha50,
          },
          width: props.fullWidth ? "100%" : undefined,
          ...props.overrides?.triggerButton,
        })}
        $align="space-between"
      >
        {props.children}
        <AccordionExpandIcon
          direction={props.overrideArrowDirection ?? (menuOpen ? "up" : "down")}
          $style={{ color: $theme.colors.primary600 }}
        />
      </Row>
    </StatefulPopover>
  );
};
