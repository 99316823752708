import { styled, useStyletron, withStyle } from "baseui";
import { defaultTo, omit } from "lodash";
import type { ReactNode } from "react";
import React, { forwardRef, useState } from "react";
import type { StyleObject } from "styletron-react";
import { AccordionExpandIcon } from "../icons/AccordionExpandIcon";
import {
  expandBaseWebBorder,
  expandBorderBottom,
  expandBorderColors,
  expandBorderRadii,
  expandPadding,
  paddingHorizontal,
  paddingUtil,
  paddingVertical,
} from "../utils";
import type { Overrides } from "../utils/overrides";
import { useOverrides } from "../utils/overrides";
import { Column } from "./Column";
import { Row } from "./Row";

const StyledCardRoot = styled<{ $focused?: boolean; $active?: boolean; $spaced?: boolean; $nested?: boolean; $flat?: boolean }, "div">(
  "div",
  ({ $theme, $active, $focused, $spaced, $nested, $flat }) => {
    return {
      ...expandPadding(defaultTo($spaced, true) ? $theme.sizing.scale600 : "0px"),
      ...expandBorderRadii($theme.borders.surfaceBorderRadius),
      ...expandBaseWebBorder({
        borderWidth: $theme.borders.border100.borderWidth,
        borderColor: $active && $focused ? $theme.colors.borderSelected : $theme.colors.borderOpaque,
        borderStyle: "solid",
      }),
      backgroundColor: $theme.colors.background,
      outlineWidth: $focused ? "2px" : $active ? "1px" : "0px",
      outlineStyle: "solid",
      outlineColor: $theme.colors.borderSelected,
      boxShadow: $nested
        ? "0px -1px 0px 0px rgba(0, 0, 0, 0.24) inset, 0px 1px 1px 0px rgba(0, 0, 0, 0.16)"
        : $flat
        ? undefined
        : $theme.lighting.depth2,
      ...$theme.typography.LabelSmall,
    };
  }
);

const StyledCardRootSecondary = withStyle(StyledCardRoot, (props: any) => {
  return {
    backgroundColor: "transparent",
    ...expandBorderColors(props.$active ? props.$theme.colors.alpha500 : props.$theme.colors.alpha300),
    boxShadow: props.$active ? props.$theme.lighting.shadowButtonInset : "none",
    outlineWidth: props.$focused ? "2px" : "0px",
    outlineOffset: props.$focused ? "1px" : "0px",
    paddingBottom: props.$theme.sizing.scale500,
  };
});

export const StyledCardTitleContainer = styled<{ $focused?: boolean; $active?: boolean; $spaced?: boolean }, "div">("div", ({ $theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: $theme.sizing.scale400,
    justifyContent: "space-between",
  };
});

export const StyledCardTitle = styled<{ $focused?: boolean; $active?: boolean; $spaced?: boolean }, "div">("div", ({ $theme }) => {
  return {
    ...$theme.typography.subHeading,
    marginBottom: $theme.sizing.scale400,
  };
});

const StyledCardSubtitle = styled<{ $focused?: boolean; $active?: boolean; $spaced?: boolean }, "div">("div", ({ $theme }) => {
  return {
    ...$theme.typography.LabelMedium,
    marginBottom: $theme.sizing.scale400,
  };
});

export type CardProps = {
  $focused?: boolean;
  $active?: boolean;
  $spaced?: boolean;
  $flat?: boolean;
  $style?: StyleObject;
  $as?: React.ElementType;
  title?: ReactNode;
  subtitle?: ReactNode;
  $nested?: boolean;
  titleAction?: ReactNode;
  variant?: "primary" | "secondary";
  overrides?: Overrides<{
    Root: typeof StyledCardRoot;
    Title: typeof StyledCardTitle;
    TitleContainer: typeof StyledCardTitleContainer;
    Subtitle: typeof StyledCardSubtitle;
  }>;
} & Omit<JSX.IntrinsicElements["div"], "title" | "ref">;

/** A rectangular box around some content */
export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const { Root, Title, TitleContainer, Subtitle } = useOverrides(
    {
      Root: props.variant == "secondary" ? StyledCardRootSecondary : StyledCardRoot,
      TitleContainer: StyledCardTitleContainer,
      Title: StyledCardTitle,
      Subtitle: StyledCardSubtitle,
    },
    props.overrides
  );

  // add padding to this card by default, but don't if explicitly told not to, or if the card contains card sections, which add their own padding
  const spaced =
    props.$spaced ??
    !React.Children.toArray(props.children).every((child) => {
      return React.isValidElement(child) && (child.type as any).displayName === "CardSection";
    });

  return (
    <Root ref={ref} {...omit(props, ["title"])} $spaced={spaced}>
      {(props.title || props.titleAction) && (
        <TitleContainer>
          <div>
            <Title>{props.title}</Title>
            {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
          </div>
          {props.titleAction}
        </TitleContainer>
      )}
      {props.children}
    </Root>
  );
});

export const CardSection = (props: {
  children: ReactNode;
  variant?: "primary" | "secondary";
  $padding?: "xsmall" | "small" | "normal";
  $as?: React.ElementType;
  $style?: StyleObject;
  $ruled?: boolean;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  "data-testid"?: string;
}) => {
  const [css, $theme] = useStyletron();
  const padding =
    props.$padding == "xsmall" ? $theme.sizing.scale300 : props.$padding == "small" ? $theme.sizing.scale500 : $theme.sizing.scale600;

  return (
    <Column
      data-testid={props["data-testid"]}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick}
      $as={props.$as ?? "section"}
      $gap={$theme.sizing.scale500}
      $style={{
        backgroundColor: props.variant === "secondary" ? $theme.colors.backgroundSecondary : "transparent",
        ...paddingUtil(padding),
        ...(props.$ruled
          ? expandBorderBottom({
              borderWidth: $theme.borders.border100.borderWidth,
              borderColor: $theme.colors.borderOpaque,
              borderStyle: "solid",
            })
          : undefined),
        ":last-child": {
          borderBottomWidth: 0,
          borderBottomLeftRadius: $theme.borders.surfaceBorderRadius,
          borderBottomRightRadius: $theme.borders.surfaceBorderRadius,
        },
        ":first-child": {
          borderTop: undefined,
          borderTopLeftRadius: $theme.borders.surfaceBorderRadius,
          borderTopRightRadius: $theme.borders.surfaceBorderRadius,
        },
        ...(omit(props.$style, "appearance") ?? {}),
      }}
    >
      {props.children}
    </Column>
  );
};
CardSection.displayName = "CardSection";

export const ExpandableCardSection = (props: {
  title: ReactNode;
  children: ReactNode;
  $style?: StyleObject;
  onExpand?: () => void;
  onCollapse?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [_, $theme] = useStyletron();

  return (
    <Column
      $style={{
        ...expandBorderBottom({
          borderWidth: $theme.borders.border100.borderWidth,
          borderColor: $theme.colors.borderOpaque,
          borderStyle: "solid",
        }),
        ":last-child": {
          borderBottomWidth: 0,
          borderBottomLeftRadius: $theme.borders.surfaceBorderRadius,
          borderBottomRightRadius: $theme.borders.surfaceBorderRadius,
        },
        ...props.$style,
      }}
    >
      <Row
        $gap={$theme.sizing.scale300}
        $style={{
          cursor: "pointer",
          color: $theme.colors.contentPrimary,
          ...paddingVertical($theme.sizing.scale200),
          ...paddingHorizontal($theme.sizing.scale300),
          ...(isOpen ? { backgroundColor: $theme.colors.alpha50 } : {}),
          ":hover": {
            backgroundColor: $theme.colors.alpha50,
          },
        }}
        onClick={() => {
          if (isOpen && props.onCollapse) {
            props.onCollapse();
          } else if (!isOpen && props.onExpand) {
            props.onExpand();
          }
          setIsOpen(!isOpen);
        }}
      >
        <AccordionExpandIcon direction={isOpen ? "down" : "right"} />
        {props.title}
      </Row>

      {isOpen && (
        <CardSection
          $style={{
            paddingTop: $theme.sizing.scale400,
            paddingRight: $theme.sizing.scale500,
          }}
        >
          {props.children}
        </CardSection>
      )}
    </Column>
  );
};
